import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const pageHomeEnter = (container) => {


    const tl = gsap.timeline();

    let body = document.getElementsByTagName('body')[0];
    let homeSection = container.querySelector('.home__section');
    let mainContent = container.querySelector('.main__content');
    let subMenu = container.querySelectorAll('.sub-menu');

    tl
        .to(homeSection, {y: '0'}, 0)

    const windowWidth = $(window).width();
    let tlTime = '14.3'
    let column = '8.333333333'
    let trigger = container.querySelector('.page__home')

    // Sections:
    let section1 = container.querySelector('.home__section__one')
    let section2 = container.querySelector('.home__section__two')
    let section3 = container.querySelector('.home__section__three')
    let section4 = container.querySelector('.home__section__four')
    let section5 = container.querySelector('.home__section__five')
    let section6 = container.querySelector('.home__section__six')
    let section7 = container.querySelector('.home__section__seven')

    // Vars:
    // Section 1:
    let homeContent = section1.querySelector('.home__content')
    let homeBgImg = section1.querySelector('.home__bg__img')
    let homeImg = section1.querySelector('.home__img')
    let homeBgBlock = section1.querySelector('.home__bg__block')

    // Section 2:
    let homeContent2 = section2.querySelector('.home__content')
    let homeBgImg2 = section2.querySelector('.home__bg__img')
    let homeImg2 = section2.querySelector('.home__img')
    let homeBgBlock2 = section2.querySelector('.home__bg__block')

    // Section 3:
    let homeContent3 = section3.querySelector('.home__content')
    let homeBgImg3 = section3.querySelector('.home__bg__img')
    let homeImg3 = section3.querySelector('.home__img')
    let homeImgInner3 = section3.querySelector('.home__img__inner')
    let homeBgBlock3 = section3.querySelector('.home__bg__block')

    // Section 4:
    let homeContent4 = section4.querySelector('.home__content')
    let homeImg4 = section4.querySelector('.home__img')
    let homeBgImg4 = section4.querySelector('.home__bg__img')
    let homeBgBlock4 = section4.querySelector('.home__bg__block')

    // Section 5:
    let homeContent5 = section5.querySelector('.home__content')
    let homeImg5 = section5.querySelector('.home__img')
    let homeBgImg5 = section5.querySelector('.home__bg__img')
    let homeBgBlock5 = section5.querySelector('.home__bg__block')

    // Section 6:
    let homeBgImg6 = section6.querySelector('.home__bg__img');
    let homeBgBlock6 = section6.querySelector('.home__bg__block');
    let homeNews = section6.querySelectorAll('.news__content__post')
    let homeNewsFirst = homeNews[0]
    let homeNewsSecond = homeNews[homeNews.length- 1]

    // Section 7:
    let homeBgImg7 = section7.querySelector('.home__bg__img');
    let homeBgBlock7 = section6.querySelector('.home__bg__block');
    let homeFooter = section7.querySelector('.home__footer');
    let homeFooterMenu1 = section7.querySelector('.footer__menu--one');
    let homeFooterMenu2 = section7.querySelector('.footer__menu--two');
    let homeFooterMenu3 = section7.querySelector('.footer__menu--three');

    let snap
    let ease
    let snapDuration = 2.5

    if (windowWidth > 1199) {
        snap = {
            snapTo: 1,
            duration: snapDuration,
        }
        ease = 'power1.out';
    } else {
        snap = false;
        ease = 'none';
    }

    // Timeline 1
    let tlMain = gsap.timeline({
        scrollTrigger: {
            trigger: trigger,
            start: 'top top',
            end: `${tlTime}% top`,
            scrub: true,
            markers: false,
            snap: snap,
            onEnter: () => body.classList.remove('search__open'),
            onEnterBack: () => body.classList.remove('search__open'),
            onLeaveBack: () => body.classList.remove('search__open'),
        },
    });

if (windowWidth > 0 &&  windowWidth <= 767) {
    //    comment
} else if (windowWidth > 767 && windowWidth <= 1199) {
    tlMain.to(homeContent, {
        y: `-${column * 12}vh`,
        ease: ease,
    }, '<');

    tlMain.to(homeImg, {
        y: `-${column * 13}vh`,
        ease: ease,
        }, '<');

    tlMain.to(homeBgImg, {
        y: `-${column * 12}vh`,
        ease: ease,
        }, '<');

    tlMain.to(homeBgBlock, {
        y: `-${column * 12}vh`,
        ease: ease,
        }, '<');

    tlMain.to(homeContent2, {
        y: `${column * 0}vh`,
        ease: ease,
        }, '<');

    tlMain.to(homeBgImg2, {
        y: `${column * 0}vh`,
        ease: ease,
        }, '<');

    tlMain.to(homeImg2, {
        y: `${column * 0}vh`,
        ease: ease,
        }, '<');

    tlMain.to(homeBgBlock2, {
        y: `${column * 0}vh`,
        ease: ease,
        }, '<');
} else if (windowWidth > 1199) {
    tlMain.to(homeContent, {
        autoAlpha: 0,
        x: `-${column * 4}vw`,
        }, '<');

    tlMain.to(homeBgImg, {
        x: `${column * 1}vw`,
        autoAlpha: 0,
        }, '<');

    tlMain.to(homeImg, {
        x: `${column * 2}vw`,
        width: `${column * 0}vw`,
        }, '<');

    tlMain.to(homeBgBlock, {
        x: `${column * 0}vw`,
        }, '<');

    tlMain.to(homeContent2, {
        opacity: 1,
        x: `${column * 7}vw`,
        }, '<');

    tlMain.to(homeBgImg2, {
        x: `${column * 1}vw`,
        }, '<');

    tlMain.to(homeImg2, {
        x: `${column * 2}vw`,
        }, '<');
}


// Timeline 2
let tlMain2 = gsap.timeline({
    scrollTrigger: {
        trigger: trigger,
        start: `${tlTime}% top`,
        end: `${tlTime * 2}% top`,
        scrub: true,
        markers: false,
        snap: snap,
        onEnter: () => body.classList.remove('search__open'),
        onEnterBack: () => body.classList.remove('search__open'),
        onLeaveBack: () => body.classList.remove('search__open'),
    },
});


if (windowWidth > 0 &&  windowWidth <= 767) {
    //    comment
} else if (windowWidth > 767 && windowWidth <= 1199) {
    tlMain2.to(homeContent2, {
        y: `-${column * 12}vh`,
        ease: ease,
        }, '<');

    tlMain2.to(mainContent, {
        backgroundColor: '#eafbe6',
        ease: ease,
    }, '<')

    tlMain2.to(homeImg2, {
        y: `-${column * 11}vh`,
        ease: ease,
        }, '<');

    tlMain2.to(homeBgImg2, {
        y: `-${column * 12}vh`,
        ease: ease,
        }, '<');

    tlMain2.to(homeBgBlock2, {
        y: `-${column * 13}vh`,
        ease: ease,
        }, '<');

    tlMain2.to(homeImg3, {
        y: `-${column * 0}vh`,
        ease: ease,
        }, '<');

    tlMain2.to(homeBgImg3, {
        y: `-${column * 0}vh`,
        ease: ease,
        }, '<');

    tlMain2.to(homeBgBlock3, {
        y: `-${column * 0}vh`,
        ease: ease,
        }, '<');

    tlMain2.to(homeContent3, {
        y: `-${column * 0}vh`,
        ease: ease,
        }, '<');
} else if (windowWidth > 1199) {
    tlMain2.to(subMenu, {
        backgroundColor: '#d1e8f8',
    }, '<');

    tlMain2.to(homeBgBlock, {
        y: `-${column * 12}vh`,
        }, '<')

    tlMain2.to(mainContent, {
        backgroundColor: '#eafbe6',
        }, '<')

    tlMain2.to(homeBgImg3, {
        autoAlpha: 1,
        duration: 0.001,
        }, '<');

    tlMain2.to(homeBgImg2, {
        autoAlpha: 0,
        }, '<')

    tlMain2.to(homeImg3, {
        autoAlpha: 1,
        duration: 0.001,
        }, '<');

    tlMain2.to(homeImg2, {
        autoAlpha: 0,
        }, '<')

    tlMain2.to(homeContent2, {
        opacity: 0,
        y: `-${column * 12}vh`,
        }, '<');

    tlMain2.to(homeBgBlock3, {
        y: 0,
        }, '<');

    tlMain2.to(homeContent3, {
        y: 0,
        autoAlpha: 1,
        }, '<');
}


// Timeline 3
let tlMain3 = gsap.timeline({
    scrollTrigger: {
        trigger: trigger,
        start: `${tlTime* 2}% top`,
        end: `${tlTime * 3}% top`,
        scrub: true,
        markers: false,
        snap: snap,
        onEnter: () => body.classList.remove('search__open'),
        onEnterBack: () => body.classList.remove('search__open'),
        onLeaveBack: () => body.classList.remove('search__open'),
    },
});

if (windowWidth > 0 &&  windowWidth <= 767) {
//    comment
} else if (windowWidth > 767 && windowWidth <= 1199) {
    tlMain3.to(homeBgBlock3, {
        y: `-${column * 12}vh`,
        ease: ease,
    }, '<');

    tlMain3.to(homeContent3, {
        y: `-${column * 12}vh`,
        ease: ease,
    }, '<');

    tlMain3.to(homeBgImg3, {
        y: `-${column * 14}vh`,
        ease: ease,
    }, '<')

    tlMain3.to(homeImg3, {
        y: `-${column * 14}vh`,
        ease: ease,
    }, '<')

    tlMain3.to(homeBgBlock4, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain3.to(homeContent4, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain3.to(homeBgImg4, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain3.to(homeImg4, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')
} else if (windowWidth > 1199) {
    tlMain3.to(homeBgBlock3, {
        x: `${column * 12}vw`,
    }, '<');

    tlMain3.to(homeContent3, {
        x: `${column * 13}vw`,
    }, '<');

    tlMain3.to(homeBgImg3, {
        x: `${column * 10}vw`,
        autoAlpha: 0,
    }, '<')

    tlMain3.to(homeImg3, {
        x: `${column * 11}vw`,
        width: 0,
    }, '<')

    tlMain3.to(homeImgInner3, {
        x: `-${column * 5}vw`,
    }, '<')

    tlMain3.to(homeImg4, {
        autoAlpha: 1,
        duration: 0.001,
    }, 0)

    tlMain3.to(homeImg4, {
        x: `${column * 6}vw`,
    }, '<')

    tlMain3.to(homeBgImg4, {
        autoAlpha: 1,
        duration: 0.001,
    }, 0)

    tlMain3.to(homeBgImg4, {
        x: `${column * 10}vw`,
    }, '<')

    tlMain3.to(homeBgBlock4, {
        x: `${column * 0}vw`,
    }, '<')

    tlMain3.to(homeContent4, {
        x: `${column * 1}vw`,
        autoAlpha: 1,
    }, '<')
}

// Timeline 4
let tlMain4 = gsap.timeline({
    scrollTrigger: {
        trigger: trigger,
        start: `${tlTime* 3}% top`,
        end: `${tlTime * 4}% top`,
        scrub: true,
        markers: false,
        snap: snap,
        onEnter: () => body.classList.remove('search__open'),
        onEnterBack: () => body.classList.remove('search__open'),
        onLeaveBack: () => body.classList.remove('search__open'),
    },
});

if (windowWidth > 0 &&  windowWidth <= 767) {
    //    comment
} else if (windowWidth > 767 && windowWidth <= 1199) {
    tlMain4.to(homeBgBlock4, {
        y: `-${column * 15}vh`,
        ease: ease,
    }, '<')

    tlMain4.to(homeContent4, {
        y: `-${column * 14}vh`,
        ease: ease,
    }, '<')

    tlMain4.to(homeImg4, {
        y: `-${column * 13}vh`,
        ease: ease,
    }, '<')

    tlMain4.to(homeBgImg4, {
        y: `-${column * 12}vh`,
        ease: ease,
    }, '<')

    tlMain4.to(homeBgBlock5, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain4.to(homeImg5, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain4.to(homeBgImg5, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain4.to(homeContent5, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')
} else if (windowWidth > 1199) {
    tlMain4.to(homeBgBlock4, {
        x: `${column * 12}vw`,
    }, '<')

    tlMain4.to(homeImg4, {
        x: `${column * 17}vw`,
    }, '<')

    tlMain4.to(homeBgImg4, {
        x: `${column * 18}vw`,
    }, '<')

    tlMain4.to(homeContent4, {
        x: `${column * 13}vw`,
    }, '<')

    tlMain4.to(homeBgBlock5, {
        x: `${column * 0}vw`,
    }, '<')

    tlMain4.to(homeImg5, {
        x: `${column * 1}vw`,
    }, '<')

    tlMain4.to(homeBgImg5, {
        x: `${column * 5}vw`,
    }, '<')

    tlMain4.to(homeContent5, {
        x: `${column * 7}vw`,
        autoAlpha: 1,
    }, '<')
}



// Timeline 5
let tlMain5 = gsap.timeline({
    scrollTrigger: {
        trigger: trigger,
        start: `${tlTime* 4}% top`,
        end: `${tlTime * 5}% top`,
        scrub: true,
        markers: false,
        snap: snap,
        onEnter: () => body.classList.remove('search__open'),
        onEnterBack: () => body.classList.remove('search__open'),
        onLeaveBack: () => body.classList.remove('search__open'),
    },
});

if (windowWidth > 0 &&  windowWidth <= 767) {
    //    comment
} else if (windowWidth > 767 && windowWidth <= 1199) {
    tlMain5.to(homeBgBlock5, {
        y: `-${column * 16}vh`,
        ease: ease,
    }, '<')

    tlMain5.to(mainContent, {
        backgroundColor: '#d1e8f8',
        ease: ease,
    }, '<')

    tlMain5.to(homeImg5, {
        y: `-${column * 15}vh`,
        ease: ease,
    }, '<')

    tlMain5.to(homeBgImg5, {
        y: `-${column * 14}vh`,
        ease: ease,
    }, '<')

    tlMain5.to(homeContent5, {
        y: `-${column * 13}vh`,
        ease: ease,
    }, '<')

    tlMain5.to(homeBgBlock6, {
        y: `${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain5.to(homeBgImg6, {
        y: `${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain5.to(homeNewsFirst, {
        y: `${column * 2}vh`,
        ease: ease,
    }, '<')

    tlMain5.to(homeNewsSecond, {
        y: `${column * 2}vh`,
        ease: ease,
    }, '<')
} else if (windowWidth > 1199) {
    tlMain5.to(subMenu, {
        backgroundColor: '#eafbe6',
    }, '<');

    tlMain5.to(homeBgBlock5, {
        backgroundColor: '#02106f',
    }, '<')

    tlMain5.to(homeImg5, {
        y: `-${column * 12}vh`,
    }, '<')

    tlMain5.to(homeBgImg5, {
        y: `-${column * 13}vh`,
    }, '<')

    tlMain5.to(homeContent5, {
        y: `-${column * 12}vh`,
        autoAlpha: 0,
    }, '<')

    tlMain5.to(mainContent, {
        backgroundColor: '#d1e8f8',
    }, '<')

    tlMain5.to(homeBgImg6, {
        y: `${column * 0}vh`,
    }, '<')

    tlMain5.to(homeNewsFirst, {
        y: `${column * 0}vh`,
        autoAlpha: 1,
    }, '<')

    tlMain5.to(homeNewsSecond, {
        y: `${column * 0}vh`,
        autoAlpha: 1,
    }, '<')
}

// Timeline 6
let tlMain6 = gsap.timeline({
    scrollTrigger: {
        trigger: trigger,
        start: `${tlTime* 5}% top`,
        end: `${tlTime * 6}% top`,
        scrub: true,
        markers: false,
        snap: snap,
        onEnter: () => body.classList.remove('search__open'),
        onEnterBack: () => body.classList.remove('search__open'),
        onLeaveBack: () => body.classList.remove('search__open'),
    },
});

if (windowWidth > 0 &&  windowWidth <= 767) {
    $('.home__news').slick({
        infinite: false,
        swipetoslide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
    });
} else if (windowWidth > 767 && windowWidth <= 1199) {
    tlMain6.to(homeBgImg6, {
        autoAlpha: 0,
        ease: ease,
    }, '<')

    tlMain6.to(homeBgImg7, {
        autoAlpha: 1,
        duration: 0.001,
        ease: ease,
    }, 0)

    tlMain6.to(homeNewsFirst, {
        y: `-${column * 11}vh`,
        ease: ease,
    }, '<')

    tlMain6.to(homeNewsSecond, {
        y: `-${column * 12}vh`,
        ease: ease,
    }, '<')

    tlMain6.to(homeBgBlock7, {
        y: `-${column * 0}vh`,
        ease: ease,
    }, '<')

    tlMain6.to(homeFooter, {
        y: `${column * 3}vh`,
        ease: ease,
    }, '<')

    tlMain6.to(homeFooterMenu1, {
        y: `${column * 0}vh`,
        ease: ease,
        autoAlpha: 1,
    }, '<')

    tlMain6.to(homeFooterMenu2, {
        y: `${column * 0}vh`,
        ease: ease,
        autoAlpha: 1,
    }, '<')

    tlMain6.to(homeFooterMenu3, {
        y: `${column * 0}vh`,
        ease: ease,
        autoAlpha: 1,
    }, '<')
} else if (windowWidth > 1199) {
    tlMain6.to(homeNewsFirst, {
        y: `-${column * 8}vh`,
        autoAlpha: 0,
    }, '<')

    tlMain6.to(homeNewsSecond, {
        y: `-${column * 7}vh`,
        autoAlpha: 0,
    }, '<')

    tlMain6.to(homeFooter, {
        y: `${column * 3}vh`,
    }, '<')

    tlMain6.to(homeFooterMenu1, {
        y: `${column * 0}vh`,
        autoAlpha: 1,
    }, '<')

    tlMain6.to(homeFooterMenu2, {
        y: `${column * 0}vh`,
        autoAlpha: 1,
    }, '<')

    tlMain6.to(homeFooterMenu3, {
        y: `${column * 0}vh`,
        autoAlpha: 1,
    }, '<')

    tlMain6.to(homeBgImg7, {
        autoAlpha: 1,
        duration: 0.001,
    }, 0)

    tlMain6.to(homeBgImg6, {
        autoAlpha: 0,
    }, 0)
}


}

export default pageHomeEnter;
